import React, { useState, useEffect } from 'react';
import { NavDropdown, Badge } from 'react-bootstrap';
import axios from 'axios';
import { Constants } from '../../utils/constants';
import moment from 'moment';
import {
    BsPerson,
    BsGearFill,
    BsPeopleFill,
    BsBell,
    BsCheckAll
} from 'react-icons/bs';

export const NotificationsPopover = ({ currentUser }) => {
    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const [activeTab, setActiveTab] = useState('unread');
    const [expandedMessages, setExpandedMessages] = useState({});

    const getNotificationIcon = (type) => {
        switch (type) {
            case 'private':
                return <BsPerson className="notification-icon" />;
            case 'public':
                return <BsGearFill className="notification-icon" />;
            case 'roleBased':
                return <BsPeopleFill className="notification-icon" />;
            default:
                return <BsBell className="notification-icon" />;
        }
    };

    const processNotifications = (notificationList, type) => {
        return (notificationList || []).map(notification => ({
            id: notification.id.toString(),
            title: notification.title,
            message: notification.body,
            createdAt: new Date(
                notification.createdAt.year,
                notification.createdAt.monthValue - 1,
                notification.createdAt.dayOfMonth,
                notification.createdAt.hour,
                notification.createdAt.minute
            ),
            isRead: notification.readStatus || false,
            type,
        }));
    };

    const fetchNotifications = async () => {
        if (loading || !currentUser?.id) return;
        setLoading(true);

        try {
            console.log('Fetching notifications for user:', currentUser.id);
            const url = `https://sandbox.spacia.page/messaging/api/v1/push-notifications?page=${page + 1}&size=220&userId=${currentUser.id}`;
            console.log('Fetching from URL:', url);

            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            console.log('Notifications response:', response.data);

            if (response.status === 200) {
                const { privateNotifications = [], publicNotifications = [], roleBasedNotifications = [] } = response.data;

                let allNotifications = [
                    ...privateNotifications.map(n => ({
                        id: n.id.toString(),
                        title: n.title,
                        message: n.body,
                        createdAt: new Date(
                            n.createdAt.year,
                            n.createdAt.monthValue - 1,
                            n.createdAt.dayOfMonth,
                            n.createdAt.hour,
                            n.createdAt.minute
                        ),
                        isRead: n.readStatus,
                        type: 'private'
                    })),
                    ...publicNotifications.map(n => ({
                        id: n.id.toString(),
                        title: n.title,
                        message: n.body,
                        createdAt: new Date(
                            n.createdAt.year,
                            n.createdAt.monthValue - 1,
                            n.createdAt.dayOfMonth,
                            n.createdAt.hour,
                            n.createdAt.minute
                        ),
                        isRead: n.readStatus,
                        type: 'public'
                    })),
                    ...roleBasedNotifications.map(n => ({
                        id: n.id.toString(),
                        title: n.title,
                        message: n.body,
                        createdAt: new Date(
                            n.createdAt.year,
                            n.createdAt.monthValue - 1,
                            n.createdAt.dayOfMonth,
                            n.createdAt.hour,
                            n.createdAt.minute
                        ),
                        isRead: n.readStatus,
                        type: 'roleBased'
                    }))
                ];

                // Sort by date
                allNotifications.sort((a, b) => b.createdAt - a.createdAt);

                console.log('Processed notifications:', allNotifications);

                setNotifications(allNotifications);
                setUnreadCount(allNotifications.filter(n => !n.isRead).length);
                setPage(prev => prev + 1);
            } else {
                console.error('Failed to fetch user notifications!!!!!!!!:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
            console.error('Error details:', error.response?.data);
        } finally {
            setLoading(false);
        }
    };

    const markAllAsRead = async () => {
        try {
            await axios.patch(
                `https://sandbox.spacia.page/messaging/api/v1/push-notifications/users/${currentUser.id}/mark-all-read`,
                {},  // empty body
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );

            setNotifications(prev =>
                prev.map(notification => ({ ...notification, isRead: true }))
            );
            setUnreadCount(0);
        } catch (error) {
            console.error('Error marking all as read:', error);
        }
    };

    const toggleMessageExpansion = (id) => {
        setExpandedMessages(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const renderMessage = (message, id) => {
        const maxLength = 100;
        if (message.length <= maxLength) return message;

        const isExpanded = expandedMessages[id];
        if (isExpanded) {
            return (
                <span>
                    {message}
                    <button
                        className="read-more-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleMessageExpansion(id);
                        }}
                    >
                        Show less
                    </button>
                </span>
            );
        }

        return (
            <span>
                {message.substring(0, maxLength)}...
                <button
                    className="read-more-button"
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleMessageExpansion(id);
                    }}
                >
                    Read more
                </button>
            </span>
        );
    };

    useEffect(() => {
        if (currentUser?.id) {
            fetchNotifications();
        }
    }, [currentUser]);

    const getFilteredNotifications = (tab) => {
        switch (tab) {
            case 'unread':
                return notifications.filter(n => !n.isRead);
            case 'read':
                return notifications.filter(n => n.isRead);
            case 'all':
                return notifications;
            default:
                return [];
        }
    };

    const updateReadStatus = async (notificationId, readStatus) => {
        try {
            const response = await axios.patch(
                `https://sandbox.spacia.page/messaging/api/v1/push-notifications/${notificationId}/users/${currentUser.id}/read-status?readStatus=${readStatus}`,
                {},  // empty body
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                console.log('Read status updated successfully');
                // Update the notification in state
                setNotifications(prev =>
                    prev.map(notification =>
                        notification.id === notificationId
                            ? { ...notification, isRead: readStatus }
                            : notification
                    )
                );
                // Update unread count
                setUnreadCount(prev => readStatus ? prev - 1 : prev);
            } else {
                console.error('Error updating read status:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error updating read status:', error);
        }
    };

    const NotificationItem = ({ notification }) => (
        <div
            className={`notification-item ${!notification.isRead ? 'unread' : ''}`}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!notification.isRead) {
                    updateReadStatus(notification.id, true);
                }
            }}
        >
            <div className="notification-icon-wrapper">
                {getNotificationIcon(notification.type)}
                {!notification.isRead && <span className="unread-dot" />}
            </div>
            <div className="notification-content">
                <div className="notification-header">
                    <h6 className="notification-title text-truncate">
                        {notification.title}
                    </h6>
                    <small className="notification-time">
                        {moment(notification.createdAt).fromNow()}
                    </small>
                </div>
                <p className="notification-message">
                    {renderMessage(notification.message, notification.id)}
                </p>
            </div>
        </div>
    );

    return (
        <div className="notification-bell-wrapper me-3">
            <NavDropdown
                align="end"
                className="notifications-dropdown"
                title={
                    <div style={{ display: 'inline-block', position: 'relative' }}>
                        <BsBell className="notification-bell" />
                        {unreadCount > 0 && (
                            <span className="notification-indicator" />
                        )}
                    </div>
                }
                id="notification-dropdown"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="notifications-header">
                    <h6 className="mb-0">Notifications ({getFilteredNotifications(activeTab).length})</h6>
                    {unreadCount > 0 && (
                        <button
                            className="mark-all-read-btn"
                            onClick={markAllAsRead}
                            title="Mark all as read"
                        >
                            <BsCheckAll size={20} />
                        </button>
                    )}
                </div>

                <div className="notification-tabs">
                    <button
                        className={`tab-button ${activeTab === 'all' ? 'active' : ''}`}
                        onClick={() => setActiveTab('all')}
                    >
                        All ({notifications.length})
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'unread' ? 'active' : ''}`}
                        onClick={() => setActiveTab('unread')}
                    >
                        Unread ({notifications.filter(n => !n.isRead).length})
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'read' ? 'active' : ''}`}
                        onClick={() => setActiveTab('read')}
                    >
                        Read ({notifications.filter(n => n.isRead).length})
                    </button>
                </div>

                <div className="notification-content-wrapper">
                    {loading ? (
                        <div className="text-center p-3">Loading...</div>
                    ) : getFilteredNotifications(activeTab).length === 0 ? (
                        <div className="text-center p-3">No notifications</div>
                    ) : (
                        getFilteredNotifications(activeTab).map(notification => (
                            <NotificationItem key={notification.id} notification={notification} />
                        ))
                    )}
                </div>
            </NavDropdown>
        </div>
    );
}; 