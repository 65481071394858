import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner, Row } from "react-bootstrap";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { TextField } from '@mui/material';
import moment from 'moment';
import Chip from '@mui/material/Chip';

function AnnouncementFormModal({
    isOpen,
    isClose,
    onSubmit,
    title,
    declineButton,
    acceptButton,
    id,
    initialTitle,
    initialMessage,
    initialSelectedRoles,
    initialUserId,
    initialDatetimeToSend,
    createdAt,
    readOnly,
    showRoles,
    showUserId,
    availableRoles
}) {
    const [formData, setFormData] = useState({
        id,
        title: initialTitle || '',
        message: initialMessage || '',
        selectedRoles: initialSelectedRoles || [],
        userId: initialUserId || '',
        datetimeToSend: initialDatetimeToSend || new Date()
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Create the payload first
        const payload = {
            id: formData.id,
            title: formData.title,
            message: formData.message,
            selectedRoles: formData.selectedRoles,
            userId: formData.userId,
            datetimeToSend: formData.datetimeToSend
        };

        // Only set loading true when actually submitting
        setLoading(true);

        // Call onSubmit and handle the promise
        onSubmit(payload)
            .then(() => {
                isClose();
            })
            .catch(error => {
                console.error("Error in submit:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const formatDate = (date) => {
        const momentDate = moment(date);
        const today = moment().startOf('day');
        const tomorrow = moment().add(1, 'days').startOf('day');

        if (momentDate.isSame(today, 'day')) {
            return `Today, ${momentDate.format('h:mm A')}`;
        } else if (momentDate.isSame(tomorrow, 'day')) {
            return `Tomorrow, ${momentDate.format('h:mm A')}`;
        } else {
            return momentDate.format('MMMM Do YYYY, h:mm A');
        }
    };

    const input = {
        backgroundColor: "#ECECEC",
        border: "1px solid #DDDDDD",
    };

    const formatDateTime = (date) => {
        if (!date) return '';
        return moment(date)
            .local()
            .format('MMMM D, YYYY [at] h:mm A');
    };

    return (
        <Modal show={isOpen} onHide={isClose}>
            <Modal.Header
                style={{
                    borderBottom: "none",
                    paddingLeft: "30px",
                    paddingBottom: 0,
                    paddingRight: "30px",
                    fontFamily: "Montserrat",
                    margin: "0px",
                }}
                closeButton
            >
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    style={{ width: "100%", textAlign: "center" }}
                >
                </Modal.Title>
            </Modal.Header>

            <div className="text-primary" style={{ textAlign: "center" }}>{title}</div>
            <div style={{ textAlign: "center", padding: 30, paddingTop: 10 }}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            style={input}
                            type="text"
                            value={initialTitle}
                            readOnly={true}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                            style={input}
                            as="textarea"
                            rows={3}
                            value={initialMessage}
                            readOnly={true}
                        />
                    </Form.Group>
                    {showRoles && initialSelectedRoles?.length > 0 && (
                        <Form.Group className="mb-3">
                            <Form.Label>Selected Roles</Form.Label>
                            <div style={{ 
                                // backgroundColor: "#ECECEC",
                                // border: "1px solid #DDDDDD",
                                borderRadius: "4px",
                                padding: "8px",
                                minHeight: "38px"
                            }}>
                                {initialSelectedRoles.map((roleValue) => {
                                    const role = availableRoles.find(r => r.value === roleValue);
                                    return (
                                        <Chip
                                            key={roleValue}
                                            label={role?.label || roleValue}
                                            style={{
                                                backgroundColor: '#007bff',
                                                color: 'white',
                                                margin: '2px',
                                                fontSize: '0.8125rem',
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </Form.Group>
                    )}
                    {showUserId && initialUserId && (
                        <Form.Group className="mb-3">
                            <Form.Label>Selected User ID</Form.Label>
                            <Form.Control
                                style={input}
                                type="text"
                                value={initialUserId}
                                readOnly={true}
                            />
                        </Form.Group>
                    )}
                    <Form.Group className="mb-3">
                        <Form.Label>Scheduled Time</Form.Label>
                        <Form.Control
                            style={input}
                            type="text"
                            value={formatDateTime(initialDatetimeToSend)}
                            readOnly={true}
                        />
                    </Form.Group>
                </Form>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                    paddingBottom: 30
                }}
            >
                <button onClick={isClose} className="btn btn-outline-primary"
                    style={{
                        borderRadius: "20px",
                        marginLeft: "0px",
                        width: "8rem"
                    }}>
                    {declineButton}
                </button>
                <button onClick={handleSubmit} className="btn btn-outline-primary"
                    style={{
                        borderRadius: "20px",
                        marginLeft: "20px",
                        width: "8rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <span>{acceptButton}</span>
                    {loading && (
                        <Spinner 
                            animation="border" 
                            role="status" 
                            style={{ 
                                width: '1rem', 
                                height: '1rem', 
                                borderWidth: '0.1em',  // Make spinner thinner
                                marginLeft: '10px'
                            }}>
                            <span className="visually-hidden"></span>
                        </Spinner>
                    )}
                </button>
            </div>
        </Modal>
    );
}

export default AnnouncementFormModal;
