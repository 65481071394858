import orderSummaryImage from "../../../../assets/img/png/order-summary-image.png";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Constants } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { toAbsoluteUrl } from "../../../../layout/helpers";
import closeButton from "../../../../assets/img/svg/blue-close-button.svg";
import { Modal } from "react-bootstrap";
import successImage from "../../../../assets/img/png/success-with-bubbles.png";
import rejectedImage from "../../../../assets/img/png/rejected-image.png";
import finishSetupIllustration from "../../../../assets/img/png/finish-setup-illustration.png";
import axios from "axios";
import SERVICES from "../../../../services";
import CryptoJS from 'crypto-js';
import aesEcb from "aes-ecb";

// import termsConditionsPDF from '../../../../assets/terms_conditions.pdf';

function roundToTwo(num: number): number {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
}

function generateKey(keyLength: number) {
    const randomChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@()+$";
    let result = "";
    for (let i = 0; i < keyLength; i++) {
        result += randomChars.charAt(
            Math.floor(Math.random() * randomChars.length)
        );
    }
    return result;
}

function hasText(text: string | null) {
    return text != null && text !== "";
}

const PackageOrderSummary = () => {
    // @ts-ignore
    const bundleTypeAndQuantities = useSelector(
        (state: { dashboard: { bundleTypeAndQuantities: any; }; }) =>
            state.dashboard.bundleTypeAndQuantities
    );
    // @ts-ignore
    const { userSessionForBundles: user } = useSelector((state) => state.auth);

    const [currency, setCurrency] = useState("");
    const [orderSummary, setOrderSummary] = useState<any>(null);
    const [summaryList, setSummaryList] = useState<any[]>([]);
    const [order, setOrder] = useState<any>({});
    const [referer, setReferer] = useState();

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [authorizationUrl, setAuthorizationUrl] = useState("");

    const location = useLocation();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [errorModal, setErrorModal] = useState(false);
    const [companyRSAPublicKey, setCompanyRSAPublicKey] = useState("");

    const handlePayment = () => {
        setShowAuthModal(true);
    };

    useEffect(() => {
        console.log("location:", location);
        const locationState = location.state;
        const currency = locationState?.currency;

        setCurrency(currency || "");

        const orderSummary = locationState?.summary;
        setOrderSummary(orderSummary);

        const referer = locationState?.referer;
        setReferer(referer);
    }, []);

    const observePaymentStatusCallBack = (
        invoiceToken: any,
        callBackurl: any
    ) => {
        console.log("callBackurl from paystack is:", callBackurl);

        const sse = new EventSource(callBackurl);
        console.log("sse:", sse);
        sse.addEventListener("event", (e: MessageEvent) => {
            console.log("in event listener:", e.data);

            let data;
            try {
                data = JSON.parse(e.data);
            } catch (ex) {
                console.log("an error occurred while parse event data.", ex);
            }

            console.log("data value:", data);
            if (data) {
                console.log("parsed data:", data);
                const paymentProcessingTimeout = Number(
                    Constants.paymentProcessingTimeout
                );

                const isPaid = data.isPaid;

                setTimeout(() => {
                    if (!isPaid) {
                        sse.close();
                    }
                }, paymentProcessingTimeout);

                if (isPaid) {
                    console.log("invoice has been paid");

                    sse.close();
                }
            } else {
                console.log("ERROR: data is null");
                // setIsSuccessful(false);
                // setIsProcessingPayment(false);
            }
        });

        sse.addEventListener("error", (e: any) => {
            console.log("addEventListener error :", e);
            // setIsSuccessful(false);
            // setIsProcessingPayment(false);
        });
    };

    useEffect(() => {
        if (orderSummary != null) {
            const order = orderSummary.order;
            const summaryList = orderSummary.summaryList;

            setOrder(order);
            setSummaryList(summaryList);
        }
    }, [orderSummary]);

    const isAllOfTheQuantityZero = () => {
        if (
            summaryList &&
            Array.isArray(summaryList) &&
            summaryList.length > 0
        ) {
            return summaryList.every((e) => e.quantity === 0);
        }

        return true;
    };

    const handleStuffOnModalHide = (type: string) => {
        switch (type) {
            case "success":
                setShowSuccessModal(false);

                if (referer && referer === "membership-plan") {
                    history.push("/membership-plan");
                } else {
                    history.push("/signin");
                }

                break;

            case "failure":
                setShowFailureModal(false);

                if (referer && referer === "package-signup") {
                    history.push("/corporate-page");
                }

                break;
        }

        const orderSummaryContainer =
            document.querySelector(".pkg-order-summary");
        if (orderSummaryContainer != null) {
            orderSummaryContainer.classList.remove("blur-background");
        }
    };

    const makeRequestToProcessPayment = (
        bit256: string,
        payload: { key?: any; data: any; },
        processPaymentUrl: string,
        userId: any,
        isMultiple: boolean
    ) => {
        try {
            // Use the original aes-ecb encryption
            const encrypted = aesEcb.encrypt(bit256, JSON.stringify(payload.data));
            payload.data = encrypted;

            axios
                .post(processPaymentUrl, payload, { params: { userId } })
                .then((res) => {
                    if (res.status === 200) {
                        const data = res.data.data;
                        const index0 = data && data[0];
                        console.log("RESPONSE DATA PAYSTACK:", data);

                        const authorizationUrl =
                            index0.paymentRequestResponse.data.authorizationUrl;
                        console.log("authorization url:", authorizationUrl);

                        setAuthorizationUrl(authorizationUrl);

                        const callbackUrl = index0 && index0["subscriptionCallbackUrl"];
                        console.log("subscription callback url:", callbackUrl);
                        console.log("order.referenceCode:", order.referenceCode);

                        observePaymentStatusCallBack(order.referenceCode, callbackUrl);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    console.log("Error:", err);
                    setIsLoading(false);
                    
                    if (err.response?.data?.message) {
                        setErrorMsg(err.response.data.message);
                    } else {
                        setErrorMsg("An error occurred while processing the payment");
                    }
                    setErrorModal(true);
                });
        } catch (error) {
            console.error("Encryption error:", error);
            setIsLoading(false);
            setErrorMsg("An error occurred during encryption");
            setErrorModal(true);
        }
    };

    const processPayment = () => {
        //e.preventDefault();

        const errors = [];

        if (errors.length === 0) {
            const requestId = uuid();
            const currentUser = SERVICES.getUser();
            const userId = currentUser
                ? currentUser.id
                : order
                    ? order.billingAccount.id
                    : 0;
            const companyId = currentUser
                ? currentUser.companyId
                : order
                    ? order.billingAccount.company.id
                    : 0;
            //const companyId = order ? order.billingAccount.company.id : 0;
            const rsaKey = order
                ? order.billingAccount.company.rsaPubliceKey
                : "";
            console.log("rsaKey: ", rsaKey);
            console.log("invoice amount: ", order.total);
            console.log("invocie referenceCode: ", order.referenceCode);

            // display spinner
            setIsLoading(true);

            var currency = SERVICES.getCurrency();
            currency = currency
                ? currency
                : summaryList
                    ? summaryList[0].bundle.pricing.currency
                    : "";
            console.log("currency:", currency);

            var invoiceTokens: any[] = [];
            invoiceTokens.push(order.referenceCode);

            const resourceUrl = `${Constants.BASE_URL}/platform/company/v1/company/${companyId}/rsa/key/public`;

            console.log(
                "[ " +
                requestId +
                " ] about to make request to fetch company RSA public key"
            );

            //  https://sandbox.spacia.page//users/v1.1/process/payment?userId=1093

            axios
                .get(resourceUrl, { params: { userId } })
                .then((res) => {
                    if (res.status === 200) {
                        let responseData = res.data.data[0];
                        console.log(
                            "[ " +
                            requestId +
                            " ] done processing request to fetch company RSA public key\n" +
                            "response data:",
                            res.data.data[0]
                        );

                        const publicKey = responseData.rsaPublicKey;
                        setCompanyRSAPublicKey(publicKey);

                        // generate 256 bit key
                        const bit256 = generateKey(32);
                        console.log("256 bit key is", bit256);

                        // encrypt 256 bit key with RSA Public Key
                        const url = `${Constants.BASE_URL}/users/v1/generate/base64/encrypted/key`;
                        axios
                            .post(url, {
                                bitKey: bit256,
                                rsaPublicKey: publicKey,
                            })
                            .then((res) => {
                                if (res.status === 200) {
                                    console.log(
                                        "encrypted key string:",
                                        res.data
                                    );
                                    const requestKey = res.data;

                                    // process payment
                                    const processPaymentUrl = `${Constants.BASE_URL}/users/v1.1/process/payment`;

                                    const payload = {
                                        key: requestKey,
                                        data: {
                                            requestId,
                                            invoiceIds: [],
                                            invoiceTokens,
                                            currency,
                                            paymentMethodCode: null,
                                            userId,
                                            paymentMethod: "webCheckout",
                                            amount: order.total,
                                            mobilewallet: null,
                                            electronicCardRequest: null,
                                            bankTransferRequest: null,
                                            isCashOnDelivery: false,
                                        },
                                    };

                                    console.log("the raw payload:", payload);

                                    makeRequestToProcessPayment(
                                        bit256,
                                        payload,
                                        processPaymentUrl,
                                        userId,
                                        false
                                    );
                                } else {
                                    setIsLoading(false);
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                let errorMsg = "An error occurred while processing payment";

                                if (err.response?.data?.message) {
                                    errorMsg = err.response.data.message;
                                }

                                setErrorModal(true);
                                setErrorMsg(errorMsg);
                            });
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err.response);

                    const {
                        response: {
                            data: { message },
                        },
                    } = err;
                    const errorMsg = message;

                    setErrorModal(true);
                    setErrorMsg(errorMsg);
                });
        }
    };

    return (
        <section
            className={"pkg-order-summary"}
            style={{ padding: "0 70px 70px 70px" }}
        >
            <header style={{ paddingTop: "30px" }}>
                <figure style={{ width: "150px" }}>
                    <img
                        alt={"logo"}
                        decoding={"async"}
                        className={"w100 img-fluid"}
                        src={toAbsoluteUrl("/img/logo-black.png")}
                    />
                </figure>
            </header>
            <div className="row">
                <div className="col-7">
                    <h4 style={{ marginTop: "30px", fontSize: "2rem" }}>
                        Order Summary
                    </h4>
                    <div style={{ fontSize: "1.17rem", marginTop: "50px" }}>
                        <h5>
                            Order No:{" "}
                            <span style={{ fontWeight: "normal" }}>
                                {order ? order.id : ""}
                            </span>
                        </h5>
                        <table style={{ tableLayout: "fixed", width: "100%" }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th scope={"col"}>Quantity</th>
                                    <th scope={"col"}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {summaryList &&
                                    Array.isArray(summaryList) &&
                                    summaryList.length > 0 &&
                                    summaryList.map((summary) => {
                                        const bundle = summary.bundle;
                                        const bundleType = bundle?.bundleType;
                                        const bundleTypeLabel =
                                            bundleType?.label;

                                        const subTotal = summary.subTotal;

                                        return (
                                            <tr key={uuid()}>
                                                <td>{bundleTypeLabel}</td>
                                                <td
                                                    style={{
                                                        borderBottom:
                                                            "1px dotted black",
                                                    }}
                                                />
                                                <td>x{summary.quantity}</td>
                                                <td>
                                                    {subTotal
                                                        ? subTotal.toLocaleString()
                                                        : 0}{" "}
                                                    {currency}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                        <div style={{ marginTop: "50px" }}>
                            <p style={{ fontSize: ".93rem", color: "#262626" }}>
                                Total + VAT 16%
                            </p>
                            <p
                                className={"text-primary mb-3"}
                                style={{
                                    fontSize: "2rem",
                                    fontWeight: 700,
                                    marginTop: "15px",
                                }}
                            >
                                {currency}.
                                <span>
                                    {order && order.total
                                        ? order.total.toLocaleString()
                                        : 0}
                                </span>
                            </p>
                            <p>
                                By Clicking “Pay” I agree to the <br />
                                company's <span style={{ textDecoration: 'underline' }}><a className='text-black' href={'/assets/terms_conditions.pdf'} target="_blank" rel="noopener noreferrer">terms & conditions</a></span> for the service
                            </p>
                        </div>

                        <div
                            className={"d-flex justify-content-between"}
                            style={{ width: "35%", marginTop: "100px" }}
                        >
                            <button
                                className="btn btn-outline-info"
                                style={{
                                    width: "100px",
                                    borderRadius: "100px",
                                    height: "40px",
                                }}
                                onClick={() => {
                                    history.go(-1);
                                }}
                            >
                                Back
                            </button>
                            <div
                                style={{
                                    display:
                                        !order || isAllOfTheQuantityZero()
                                            ? "none"
                                            : "block",
                                }}
                            >
                                <button
                                    className="btn btn-outline-info"
                                    style={{
                                        width: "160px",
                                        borderRadius: "100px",
                                        height: "40px",
                                    }}
                                    onClick={() => {
                                        setShowConfirmModal(true);
                                    }}
                                >
                                    Pay
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-5 order-summary-bg">
                    <figure>
                        <img
                            className={"order-summary"}
                            src={orderSummaryImage}
                            alt="Order Summary"
                        />
                    </figure>
                </div>
            </div>

            {/* payment successful modal */}
            <Modal
                show={showSuccessModal}
                onHide={() => handleStuffOnModalHide("success")}
            >
                <header className={"position-relative"} style={{ zIndex: 3 }}>
                    <figure className="text-right" style={{ padding: "15px" }}>
                        <img
                            onClick={() => handleStuffOnModalHide("success")}
                            src={closeButton}
                            alt="close button"
                            style={{ width: "25px" }}
                        />
                    </figure>
                </header>
                <Modal.Body style={{ height: "320px" }}>
                    <div
                        className="text-center position-relative"
                        style={{ bottom: "15px" }}
                    >
                        <figure>
                            <img
                                className={"position-relative"}
                                src={successImage}
                                alt="Success"
                                style={{ width: "40%" }}
                            />
                        </figure>
                        <p
                            className={"text-primary font-weight-bolder mt-5"}
                            style={{ fontSize: "1.3rem" }}
                        >
                            Payment Successful!
                        </p>
                        <div
                            className={"mt-4"}

                        >
                            <button
                                className="btn btn-primary"
                                style={{ borderRadius: "30px" }}
                                onClick={() =>
                                    handleStuffOnModalHide("success")
                                }
                            >
                                Back to Membership Plan
                            </button>
                        </div>
                        <div
                            className={"mt-4"}

                        >
                            <button
                                className="btn btn-primary"
                                style={{ borderRadius: "30px" }}
                                onClick={() =>
                                    handleStuffOnModalHide("success")
                                }
                            >
                                Sign in
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* payment failed modal */}
            <Modal
                show={showFailureModal}
                onHide={() => handleStuffOnModalHide("failure")}
            >
                <header className={"position-relative"} style={{ zIndex: 3 }}>
                    <figure className="text-right" style={{ padding: "15px" }}>
                        <img
                            onClick={() => handleStuffOnModalHide("failure")}
                            src={closeButton}
                            alt="close button"
                            style={{ width: "25px" }}
                        />
                    </figure>
                </header>
                <Modal.Body style={{ height: "300px" }}>
                    <div
                        className="text-center position-relative"
                        style={{ bottom: "15px" }}
                    >
                        <figure>
                            <img
                                className={"position-relative"}
                                src={rejectedImage}
                                alt="Failure"
                                style={{ width: "25%" }}
                            />
                        </figure>
                        <p
                            className={"text-primary font-weight-bolder mt-5"}
                            style={{ fontSize: "1.3rem" }}
                        >
                            Payment Failed!
                        </p>
                        <div
                            className={"mt-4"}

                        >
                            <button
                                className="btn btn-primary"
                                style={{ borderRadius: "30px" }}
                                onClick={() =>
                                    handleStuffOnModalHide("failure")
                                }
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* confirm payment modal */}
            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                contentClassName="padding-40px"
                style={{
                    overflow: "hidden",
                    borderRadius: "30px",
                }}
            // size="lg"
            >
                <Modal.Header
                    style={{
                        borderBottom: "none",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        margin: "0px",
                    }}
                    closeButton
                >
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ width: "100%", textAlign: "center" }}
                    >
                        {/* <h6 className="text-primary">Confirm Payment</h6> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{}}>
                    <div
                        className="text-center position-relative"
                        style={{ bottom: "15px" }}
                    >
                        <span
                            className={"text-primary mt-5"}
                            style={{ fontSize: "1.3rem", fontWeight: 200 }}
                        >
                            You are about to be redirected to checkout
                        </span>
                        <div
                            className={"mt-4"}

                        >
                            <button
                                className="btn btn-outline-primary"
                                style={{
                                    borderRadius: "20px",
                                    marginLeft: "0px",
                                    width: "8rem"
                                }}
                                onClick={() => setShowConfirmModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-outline-primary"
                                style={{
                                    borderRadius: "20px",
                                    marginLeft: "20px",
                                    width: "8rem"
                                }}
                                onClick={() => {
                                    processPayment();
                                    setShowConfirmModal(false);
                                    setShowAuthModal(true);
                                    // history.push("/sign-in");
                                }}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </Modal.Body>
                {/* <ModalFooter */}
            </Modal>

            {/* authentication modal */}

            <Modal
                show={showAuthModal}
                onHide={() => {
                    setShowAuthModal(false);
                    user ? history.push("/startBooking") : history.push("/signin");
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    style={{
                        borderBottom: 'none',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        margin: '0px',
                    }}
                    closeButton
                >
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ width: '100%', textAlign: 'center' }}
                    >
                        <h6
                            style={{ fontSize: '1.3rem', fontWeight: 200 }}
                            className="text-primary"
                        >
                            Web Checkout
                        </h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{ 
                        height: '850px', 
                        padding: '0px', 
                        width: '450px',
                        margin: 'auto',
                    }}
                >
                    <iframe
                        src={authorizationUrl}
                        style={{
                            padding: '0px',
                            width: '100%',
                            height: '100%',
                            margin: '0',
                            border: 'none',
                        }}
                        title="Web Checkout"
                    ></iframe>
                </Modal.Body>
                <Modal.Footer
                    style={{
                        borderTop: 'none',
                        padding: '0px',
                        margin: '0px',
                    }}
                >
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default PackageOrderSummary;
