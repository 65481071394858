/**
 * Project: spaces
 * File: Footer
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */
import React, { useState } from "react";
import {
    CalendarOutline, CartOutline,
    CheckmarkCircleOutline, CloseCircle,
    CloseCircleOutline, EllipsisVertical, LogOutOutline, PersonOutline,
    SearchOutline, ShareOutline
} from 'react-ionicons';
import { toAbsoluteUrl } from "../helpers";
import { useHistory } from "react-router-dom";
import SERVICES from "../../services";
import { resetTotalAmount, saveCartDetails, setCartEntries } from "../../redux/actions/dashboard";
import { useDispatch } from "react-redux";
import logo from '../../assets/img/spacia/web/spacia_logo.png';
import { Constants } from "../../utils/constants";


export function FooterElements(this: any) {
    const [currentUser, setCurrentUser] = useState({});

    const dispatch = useDispatch();
    const history = useHistory();
    const renderMenusByRole = () => {
        const currentUser = SERVICES.getUser();
        const currentUserRole = currentUser?.role;

        if (!currentUserRole) {
            // Return only the Sign In button when user is not authenticated
            return [
                <li key="signin">
                    <div role={'button'} onClick={() => history.push('/signin')} className={'item'} >
                        <div className="icon-box bg-transparent">
                            <PersonOutline color={'#000'} />
                        </div>
                        <div className="in">
                            Sign In
                        </div>
                    </div>
                </li>
            ];
        }

        const menuItems = Constants.getMenusForMobileByRole(currentUserRole).map((menu, i) => {
            return (
                <li key={i}>
                    <div
                        role={'button'}
                        onClick={() => handleNavigate(menu.route)}
                        className={`item ${activeMenu === menu.route ? 'active' : ''}`}
                    >
                        <div className="icon-box bg-transparent">
                            {menu.icon}
                        </div>
                        <div className="in">
                            {menu.name}
                        </div>
                    </div>
                </li>
            );
        });


        // Add logout button for authenticated users
        menuItems.push(
            <li key="logout">
                <div role={'button'} onClick={logout} className={'item'} >
                    <div className="icon-box bg-transparent">
                        <LogOutOutline color={'#000'} />
                    </div>
                    {/* for blue logout button */}
                    {/* <div className="in logout"> */}
                    <div className="">
                        Logout
                    </div>
                </div>
            </li>
        );

        return menuItems;
    };
    const storageKeys = ['companyId', 'currentUser', 'persist:root', 'userCurrency'];

    // const handleNavigate = (e: string) => {
    //     history.push(e);
    // };

    const logout = () => {
        const currentUser = SERVICES.getUser();
        const userId = currentUser ? currentUser.id : 0;

        const storedListingData = SERVICES.getListingData();
        const listingOwnerId = storedListingData ? storedListingData.ownerId : 0;

        if (userId && listingOwnerId && (userId === listingOwnerId)) {
            // if current user has unfinished listings, remove items from storage leaving only owner's listing specific data
            storageKeys.forEach(key => localStorage.removeItem(key));
        } else {
            localStorage.clear();
        }

        history.push("/signin");

        // reset cart entries
        dispatch(saveCartDetails([]));
        dispatch(setCartEntries());
        dispatch(resetTotalAmount());
    };

    const redirectToHome = () => {
        history.push('/home');
    };
    const [activeMenu, setActiveMenu] = useState('');

    const handleNavigate = (route: any) => {
        setActiveMenu(route);
        history.push(route);
    };



    return (
        <>
            <div className="modal fade panelbox panelbox-left" id="sidebarPanel" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className="profileBox pt-2 pb-2">

                                <a href="#" className="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                                    <CloseCircleOutline
                                        color={'#00000'}
                                        title={''}
                                    />


                                </a>
                            </div>
                            <div className="mt-2 d-flex align-items-center justify-content-center flex-column">
                                <img
                                    src={toAbsoluteUrl('/assets/img/spacia/web/spacia_logo.png')}
                                    alt={'spacia logo'}
                                    onClick={redirectToHome}
                                />
                            </div>
                            <div className={'mt-5'}>&nbsp;</div>
                            <hr className="bg-secondary border-1 border-top border-secondary" />
                            <ul className="listview flush transparent image-listview">
                                {
                                    renderMenusByRole()
                                }
                                {/* <li>
                                    <div role={'button'} onClick={logout} className={'item'} >
                                        <div className="icon-box bg-transparent">
                                            <LogOutOutline
                                                color={'#000'}
                                            />
                                        </div>
                                        <div className="in logout">
                                            Logoutnn
                                        </div>
                                    </div>
                                </li> */}


                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal inset fade action-sheet ios-add-to-home" id="ios-add-to-home-screen" tabIndex={-1}
                role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add to Home Screen</h5>
                            <a href="#" className="close-button" data-bs-dismiss="modal">
                                <CloseCircle
                                    color={'#00000'}
                                    title={''}


                                />

                            </a>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content text-center">
                                <div className="mb-1"><img src={logo} alt="image"
                                    className="imaged w90 mb-2" />
                                </div>
                                <div>
                                    Install <strong>Spacia</strong> on your iPhone's home screen.
                                </div>
                                <div>
                                    Tap <ShareOutline
                                        color={'#00000'}
                                        title={''}


                                    />
                                    and Add to homescreen.
                                </div>
                                <div className="mt-2">
                                    <button className="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal inset fade action-sheet android-add-to-home" id="android-add-to-home-screen"
                tabIndex={-1}
                role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add to Home Screen</h5>
                            <a href="#" className="close-button" data-bs-dismiss="modal">
                                <CloseCircle
                                    color={'#00000'}
                                    title={''}


                                />
                            </a>
                        </div>
                        <div className="modal-body">
                            <div className="action-sheet-content text-center">
                                <div className="mb-1">
                                    <img src={logo} alt="image" className="imaged w64 mb-2" />
                                </div>
                                <div>
                                    Install <strong>Spacia</strong> on your Android's home screen.
                                </div>
                                <div>
                                    Tap <EllipsisVertical
                                        color={'#00000'}
                                        title={''}


                                    />
                                    and Add to homescreen.
                                </div>
                                <div className="mt-2">
                                    <button className="btn btn-primary btn-block" data-bs-dismiss="modal">CLOSE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
