import React from "react";
import { Component } from "react";
import { Row } from "react-bootstrap";
import { FaCamera } from "react-icons/fa";
import defaultProfileImage from "../../assets/img/profileplace.png";
import { userServiceRxJS } from "./services/UserServiceRxJS.jsx";
import { SuccessAlert } from './alerts/SuccessAlert';
import { ErrorAlert } from './alerts/ErrorAlert';
import { inputValidationService } from './services/InputValidationService';

export class Profile extends Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      phoneNoOptI: "",
      phoneNoOptII: "",
      isFirstNameInvalid: false,
      isLastNameInvalid: false,
      isPhoneNoOpt1Invalid: false,
      isPhoneNoOpt2Invalid: false,
      hasAvatar: false,
      avatar: "",
      showSuccess: false,
      feedbackMessage: "",
      showError: false
    };
  }

  userDetailsSubscription = null;
  updateUserDetailsSubscription = null;

  componentDidMount = () => {
    const currentUserDetails = this.currentUserDetails();
    const id = currentUserDetails?.id;

    //fetch user details from web service
    userServiceRxJS.getUserDetails(id);
    // console.log(userServiceRxJS.getUserDetails(id))

    //listen for response on fetch user details from web services
    this.userDetailsSubscription = userServiceRxJS.userDetailsSubject.subscribe((e) => {
      const keys = Object.keys(e);
      if (keys.length > 0) {
        const status = e?.status;
        if (status === 200) {
          const data = e?.data;
          if (data) {
            data.map((e) => {
              this.setState({
                firstName: e?.firstName,
                lastName: e?.lastName,
              });

              const contacts = e?.contacts;
              const phoneNos = this.fetchPhoneNumbers(contacts);

              if (phoneNos.length > 0) {
                this.setState({
                  phoneNoOptI: phoneNos[0],
                  phoneNoOptII: phoneNos[1],
                });
              }

              const avatar = e?.avatar;
              if (avatar && avatar.trim() !== "") {
                this.setState({
                  avatar: avatar,
                  hasAvatar: true,
                });
              }
              return null;
            });
          }
        }
      }
    });

    //listen to response from update user details
    this.updateUserDetailsSubscription = userServiceRxJS.updateUserDetailsSubject.subscribe((e) => {
      const keys = Object.keys(e);
      if (keys.length > 0) {
        const status = e?.status;
        if (status === 200) {

          console.log(e);

          this.setState({
            showSuccess: true,
            showError: false,
            feedbackMessage: "Update completed successfully"
          });

        } else {
          this.setState({
            showSuccess: false,
            showError: true,
            feedbackMessage: "Error occurred while updating your profile."
          });
        }
      }
    });
  };

  componentWillUnmount = () => {

    if (this.userDetailsSubscription !== null) {
      this.userDetailsSubscription.complete();
    }

    if (this.updateUserDetailsSubscription !== null) {
      this.updateUserDetailsSubscription.complete();
    }

    userServiceRxJS.resetUpdateUserDetailsSubject();
    userServiceRxJS.resetUserDetailsSubject();

    this.setState({
      showSuccess: false,
      showError: false
    });

  }

  fetchPhoneNumbers = (contacts) => {
    const phoneNos = [];
    if (contacts !== undefined && contacts !== null) {
      const keys = Object.keys(contacts);
      if (keys.length > 0) {
        contacts.map((contact) => {
          const type = contact?.type;
          const value = contact?.value;
          if (type !== undefined && type === "mobile") {
            phoneNos.push(value);
          }
          return null;
        });
      }
    }
    return phoneNos;
  };

  onSubmit = () => {
    let payload = {};
    const { firstName, lastName, phoneNoOptI, phoneNoOptII, avatar } = this.state;

    const errors = [];

    if (avatar) {
      this.setState({
        hasAvatar: true,
        avatar
      })
    }

    if (
      firstName === undefined ||
      firstName.trim() === "" ||
      firstName.trim().length === 0
    ) {
      errors.push("invalid first name");
      this.setState({
        isFirstNameInvalid: true,
      });
    }

    if (
      lastName === undefined ||
      lastName.trim() === "" ||
      lastName.trim().length === 0
    ) {
      errors.push("Invalid last name");
      this.setState({
        isLastNameInvalid: true,
      });
    }

    const isPhone1Valid = this.validatePhone(phoneNoOptI, false);
    if (!isPhone1Valid) {
      errors.push("invalid mobile phone number 1");
      this.setState({
        isPhoneNoOpt1Invalid: true,
      });
    } else {
      this.setState({
        isPhoneNoOpt1Invalid: false,
      });
    }

    const isPhone2Valid = this.validatePhone(phoneNoOptII, true);
    if (!isPhone2Valid) {
      errors.push("invalid mobile phone number 2");
      this.setState({
        isPhoneNoOpt2Invalid: true,
      });
    } else {
      this.setState({
        isPhoneNoOpt2Invalid: false,
      });
    }

    if (errors.length === 0) {
      payload["firstName"] = firstName;
      payload["lastName"] = lastName;

      const contacts = [
        {
          deviceTokens: [],
          isContactable: true,
          isPrimary: true,
          type: "mobile",
          value: phoneNoOptI,
        },
        {
          deviceTokens: [],
          isContactable: true,
          isPrimary: false,
          type: "mobile",
          value: phoneNoOptII,
        },
      ];

      payload["contacts"] = contacts;
      payload["avatar"] = avatar || null;

      const currentUserDetails = this.currentUserDetails();
      const id = currentUserDetails?.id;
      const token = currentUserDetails?.token;

      userServiceRxJS.updateUserDetails(id, payload, token);
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      [name]: value,
    });
  };

  validatePhone = (value, isOptional) => {
    let answer = false;

    if (isOptional) {
      answer = true;
    } else {
      answer = inputValidationService.validatePhoneNo(value);
    }

    return answer;
  };

  currentUserDetails = () => {
    let userDetails = {};
    const jsonStr = localStorage.getItem("currentUser");

    if (jsonStr !== undefined) {
      userDetails = JSON.parse(jsonStr);
    }

    return userDetails;
  }

  toastCloseHandler = (value) => {
    this.setState({
      showSuccess: value
    })
  }

  triggerImage = (e) => {
    this.imageElement.click();
  }

  toastCloseErrorHandler = (value) => {
    this.setState({
      showError: value
    })
  }

  getBase64 = (file) => new Promise(function (resolve, reject) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject('Error: ', error);
  })

  changeImage = (e) => {
    const file = e.target.files[0];

    this.getBase64(file)
      .then(result => this.setState({ hasAvatar: true, avatar: result }))
      .catch(e => console.log(e));
  }

  render() {
    const {
      firstName,
      lastName,
      phoneNoOptI,
      phoneNoOptII,
      isFirstNameInvalid,
      isLastNameInvalid,
      isPhoneNoOpt1Invalid,
      isPhoneNoOpt2Invalid,
      hasAvatar,
      avatar,
      showSuccess,
      feedbackMessage,
      showError
    } = this.state;

    return (
      <div>
        <SuccessAlert show={showSuccess} message={feedbackMessage} close={this.toastCloseHandler} />
        <ErrorAlert show={showError} message={feedbackMessage} close={this.toastCloseErrorHandler} />
        <p>&nbsp;</p>
        <h4>Edit Profile</h4>
        <div className="ellipse">
          <img src={hasAvatar ? avatar : defaultProfileImage} onClick={this.triggerImage} alt={""} className="ellipse" />
          <FaCamera className="ellipseAttribute" color="blue" />
        </div>
        {/* invisible input field for image */}
        <input style={{ display: 'none' }} type="file" ref={input => this.imageElement = input} onChange={(e) => this.changeImage(e)} id="" />
        <form>
          <br />
          <Row>
            <div className="form-group col">
              <label htmlFor="">First Name</label>
              <input
                type="text"
                required={true}
                className={
                  isFirstNameInvalid
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="firstName"
                onChange={(e) => {
                  this.handleChange(e);
                }}
                defaultValue={firstName}
                id=""
                aria-describedby="emailHelpId"
                placeholder=""
              />
              <div className="invalid-feedback">
                your first name is required
              </div>
            </div>
            <br />
            <div className="form-group col">
              <label htmlFor="">Last Name</label>
              <input
                type="email"
                className={
                  isLastNameInvalid ? "form-control is-invalid" : "form-control"
                }
                name="lastName"
                onChange={(e) => {
                  this.handleChange(e);
                }}
                defaultValue={lastName}
                id=""
                aria-describedby="emailHelpId"
                placeholder=""
              />
              <div className="invalid-feedback">your last name is required</div>
            </div>
          </Row>
          <br />

          <div className="form-group">
            <label htmlFor="">Phone Number</label>
            <input
              type="email"
              className={
                isPhoneNoOpt1Invalid
                  ? "form-control is-invalid"
                  : "form-control"
              }
              name="phoneNoOptI"
              onChange={(e) => {
                this.handleChange(e);
              }}
              defaultValue={phoneNoOptI}
              id=""
              aria-describedby="emailHelpId"
              placeholder=""
            />
            <div className="invalid-feedback">
              your mobile phone number is required
            </div>
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="">Alternative Phone Number</label>
            <input
              type="text"
              className={
                isPhoneNoOpt2Invalid
                  ? "form-control is-invalid"
                  : "form-control"
              }
              name="phoneNoOptII"
              onChange={(e) => {
                this.handleChange(e);
              }}
              defaultValue={phoneNoOptII}
              id=""
              aria-describedby="emailHelpId"
              placeholder=""
            />
            <div className="invalid-feedback">
              your alternative mobile phone number is invalid
            </div>
          </div>
          <br />

          <button
            type="button"
            className="button"
            onClick={() => this.onSubmit()}
          >
            Save
          </button>
        </form>
      </div>
    );
  }
}

export default Profile;
