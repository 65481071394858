// @ts-nocheck

/**
 * Project: spaces
 * File: Profile
 * Created by Pennycodes on 2/5/2022.
 * Copyright spaces
 */
import React, { useEffect, useState, useRef } from "react";
import { FaCamera } from "react-icons/fa";
import SERVICES from "../../services";
import { PageTitle } from "../../layout/PageData";
import { User } from "../../utils/interfaces";
import default_avatar from "../../assets/img/avatar.png";
import { SuccessAlert } from "../../components/Settings/alerts/SuccessAlert";
import { ErrorAlert } from "../../components/Settings/alerts/ErrorAlert";
import { inputValidationService } from "../../components/Settings/services/InputValidationService";
import { userServiceRxJS } from "../../components/Settings/services/UserServiceRxJS.jsx";

const Profile: React.FC = (props) => {
    const [fullName, setFullName] = useState(<h1 className={'mx-3'} />);
    const [profileImage, setProfileImage] = useState(default_avatar);
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        phoneNoOptI: '',
        phoneNoOptII: '',
    });
    const [isFirstNameInvalid, setIsFirstNameInvalid] = useState(false);
    const [isLastNameInvalid, setIsLastNameInvalid] = useState(false);
    const [isPhoneNoOpt1Invalid, setIsPhoneNoOpt1Invalid] = useState(false);
    const [isPhoneNoOpt2Invalid, setIsPhoneNoOpt2Invalid] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');


    const fileInputRef = useRef(null);

    useEffect(() => {
        const currentUser = SERVICES.getUser() as User;
        console.log('current user:', currentUser);

        const firstName = currentUser?.firstName;
        const lastName = currentUser?.lastName;

        let fullName = <h1 className={'mx-3'} />;
        if (firstName && lastName) {
            fullName = <h1 className={'mx-3'}> {firstName} <br />{lastName}</h1>;
        } else if (firstName) {
            fullName = <h1 className={'mx-3'}> {firstName} </h1>;
        } else if (lastName) {
            fullName = <h1 className={'mx-3'}> {lastName} </h1>;
        }

        setFullName(fullName);

        const avatar = currentUser?.avatar;
        setProfileImage(avatar || default_avatar);

        // Pre-fill the form with the current user's data
        setUserData({
            firstName: currentUser?.firstName || '',
            lastName: currentUser?.lastName || '',
            phoneNoOptI: currentUser?.contacts?.[0]?.value || '',
            phoneNoOptII: currentUser?.contacts?.[1]?.value || '',
        });
    }, []);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setUserData({
            ...userData,
            [name]: value
        });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        console.log("Submit button clicked");
    
        const { firstName, lastName, phoneNoOptI, phoneNoOptII } = userData;
        const errors = [];
    
        if (!firstName || firstName.trim() === "") {
            errors.push("invalid first name");
            setIsFirstNameInvalid(true);
            console.log("First name is invalid");
        } else {
            setIsFirstNameInvalid(false);
            console.log("First name is valid");
        }
    
        if (!lastName || lastName.trim() === "") {
            errors.push("invalid last name");
            setIsLastNameInvalid(true);
            console.log("Last name is invalid");
        } else {
            setIsLastNameInvalid(false);
            console.log("Last name is valid");
        }
    
        const isPhone1Valid = phoneNoOptI ? inputValidationService.validatePhoneNo(phoneNoOptI) : true;
        if (!isPhone1Valid) {
            errors.push("invalid mobile phone number 1");
            setIsPhoneNoOpt1Invalid(true);
            console.log("Phone number 1 is invalid");
        } else {
            setIsPhoneNoOpt1Invalid(false);
            console.log("Phone number 1 is valid");
        }
    
        const isPhone2Valid = phoneNoOptII ? inputValidationService.validatePhoneNo(phoneNoOptII) : true;
        if (!isPhone2Valid) {
            errors.push("invalid mobile phone number 2");
            setIsPhoneNoOpt2Invalid(true);
            console.log("Phone number 2 is invalid");
        } else {
            setIsPhoneNoOpt2Invalid(false);
            console.log("Phone number 2 is valid");
        }
    
        if (errors.length === 0) {
            console.log("No validation errors");
            const payload = {
                firstName,
                lastName,
                contacts: [
                    {
                        deviceTokens: [],
                        isContactable: true,
                        isPrimary: true,
                        type: "mobile",
                        value: phoneNoOptI,
                    },
                    {
                        deviceTokens: [],
                        isContactable: true,
                        isPrimary: false,
                        type: "mobile",
                        value: phoneNoOptII,
                    },
                ].filter(contact => contact.value),
                avatar: profileImage || null
            };
    
            console.log("Payload:", payload);
    
            const currentUser = SERVICES.getUser() as User;
            const userId = currentUser?.id;
            const token = currentUser?.token;
    
            if (!userId || !token) {
                console.log("User ID or token is missing");
                setShowError(true);
                setFeedbackMessage("Error: User information is incomplete.");
                return;
            }
    
            console.log("User ID and token are available");
    
            userServiceRxJS.updateUserDetails(userId, payload, token)
                .then(response => {
                    console.log("Update successful:", response);
                    setShowSuccess(true);
                    setShowError(false);
                    setFeedbackMessage("Profile updated successfully.");
                })
                .catch(error => {
                    console.log("Error updating profile:", error);
                    setShowError(true);
                    setShowSuccess(false);
                    setFeedbackMessage("Error updating profile: " + (error.message || "Unknown error"));
                });
        } else {
            console.log("Validation errors found:", errors);
            setShowError(true);
            setShowSuccess(false);
            setFeedbackMessage("Please correct the highlighted errors.");
        }
    };
    



    const toastCloseHandler = () => {
        setShowSuccess(false);
        setFeedbackMessage("");
    };

    const toastCloseErrorHandler = () => {
        setShowError(false);
        setFeedbackMessage("");
    };

    const triggerImage = () => {
        fileInputRef.current.click();
    };

    const handleProfileImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    console.log("Render: showSuccess =", showSuccess, "showError =", showError, "feedbackMessage =", feedbackMessage);

    return (

        <div className='mobile-view'>
            <div className="section mt-5 p-3">
                <PageTitle>Settings</PageTitle>
                <div className="edit-profile-section p-3">
                    <h4 className="text-center">Edit Profile</h4>
                    <SuccessAlert show={showSuccess} message={feedbackMessage} close={toastCloseHandler} />
                    <ErrorAlert show={showError} message={feedbackMessage} close={toastCloseErrorHandler} />
                    <form onSubmit={onSubmit} className="space-y-4">
                        <div className="form-group">
                            <label className="block mb-1">Profile Picture</label>
                            <div className="relative">
                                <div className="ellipse">
                                    <img
                                        src={profileImage}
                                        onClick={triggerImage}
                                        alt=""
                                        className="ellipse w-24 h-24 object-cover mx-auto cursor-pointer"
                                    />
                                    <FaCamera
                                        className="ellipseAttribute absolute bottom-0 right-0 text-blue-600 cursor-pointer"
                                        onClick={triggerImage}
                                    />
                                </div>
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    onChange={handleProfileImageChange}
                                    accept="image/*"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="block mb-1">First Name</label>
                            <input
                                type="text"
                                className={`form-control ${isFirstNameInvalid ? "is-invalid" : ""}`}
                                value={userData.firstName}
                                onChange={handleChange}
                                name="firstName"
                                placeholder="Enter first name"
                            />
                            {isFirstNameInvalid && <div className="invalid-feedback">Your first name is required</div>}
                        </div>
                        <div className="form-group">
                            <label className="block mb-1">Last Name</label>
                            <input
                                type="text"
                                className={`form-control ${isLastNameInvalid ? "is-invalid" : ""}`}
                                value={userData.lastName}
                                onChange={handleChange}
                                name="lastName"
                                placeholder="Enter last name"
                            />
                            {isLastNameInvalid && <div className="invalid-feedback">Your last name is required</div>}
                        </div>
                        <div className="form-group">
                            <label className="block mb-1">Phone Number</label>
                            <input
                                type="text"
                                className={`form-control ${isPhoneNoOpt1Invalid ? "is-invalid" : ""}`}
                                value={userData.phoneNoOptI}
                                onChange={handleChange}
                                name="phoneNoOptI"
                                placeholder="Enter phone number"
                            />
                            {isPhoneNoOpt1Invalid && <div className="invalid-feedback">Your mobile phone number is required</div>}
                        </div>
                        <div className="form-group">
                            <label className="block mb-1">Alternative Phone Number</label>
                            <input
                                type="text"
                                className={`form-control ${isPhoneNoOpt2Invalid ? "is-invalid" : ""}`}
                                value={userData.phoneNoOptII}
                                onChange={handleChange}
                                name="phoneNoOptII"
                                placeholder="Enter alternative phone number"
                            />
                            {isPhoneNoOpt2Invalid && <div className="invalid-feedback">Your alternative mobile phone number is invalid</div>}
                        </div>
                        <button type="submit" className="button w-full mt-4 py-2 bg-blue-600 text-white rounded">Save</button>
                    </form>
                </div>
            </div>
        </div>
    );

};

export default Profile;
