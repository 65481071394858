import React, { useState, useEffect } from "react";
import {
    Container,
    Toast,
    Form,
    Button,
    Alert
} from "react-bootstrap";
import axios from "axios";
import SERVICES from '../../../../services';
import { Constants } from '../../../../utils/constants';
import SuccessAlert from "../../../../components/Settings/alerts/SuccessAlert";
import { ErrorAlert } from "../../../../components/Settings/alerts/ErrorAlert";

const Packages = () => {
    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [newPrice, setNewPrice] = useState("");
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchPackages();
    }, []);

    const fetchPackages = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const currentUser = SERVICES.getUser();
            const userId = currentUser ? currentUser.id : 0;
            const response = await axios.get(`${Constants.BASE_URL}/users/v1/bundle/pricings`, {
                params: { userId },
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            if (response.data && Array.isArray(response.data.data)) {
                setPackages(response.data.data);
            } else {
                throw new Error("Invalid response format");
            }
        } catch (error) {
            console.error("Error fetching packages:", error);
            setError("Failed to fetch packages. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    const handlePackageSelect = (event) => {
        const selected = packages.find(pkg => pkg.id === parseInt(event.target.value));
        setSelectedPackage(selected);
        setNewPrice(selected ? selected.cost.toString() : "");
    };

    const handlePriceChange = (event) => {
        setNewPrice(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!selectedPackage) {
            setErrorMessage("Please select a package");
            setShowErrorAlert(true);
            return;
        }

        try {
            const currentUser = SERVICES.getUser();
            const userId = currentUser ? currentUser.id : 0;
            const response = await axios.put(
                `${Constants.BASE_URL}/users/v1/bundle/pricings/${selectedPackage.id}`,
                {
                    ...selectedPackage,
                    cost: parseFloat(newPrice)
                },
                {
                    params: { userId },
                    withCredentials: true
                }
            );

            if (response.status === 200) {
                setShowSuccessAlert(true);
                fetchPackages();
                setSelectedPackage(null);
                setNewPrice("");
            } else {
                setErrorMessage("Failed to update price. Please try again.");
                setShowErrorAlert(true);
            }
        } catch (error) {
            console.error("Error updating price:", error);
            setErrorMessage("Failed to update price");
            setShowErrorAlert(true);
        }
    };


    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <div className="page-container">
            <div className="content-wrap">
                <Container>
                    {/* Success and Error Alerts */}
                    <SuccessAlert show={showSuccessAlert} message="Price updated successfully" close={() => setShowSuccessAlert(false)} />
                    <ErrorAlert show={showErrorAlert} message={errorMessage} close={() => setShowErrorAlert(false)} />

                    {/* Page Header */}
                    <div className="header">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                            <h4>Manage Packages</h4>  {/* Adjusted to match smaller heading size */}
                        </div>
                    </div>

                    {/* Main Content in a Toast (white container style) */}
                    <Toast style={{ width: "100%", padding: 20, marginBottom: 50, maxWidth: '100%' }}>
                        {packages.length === 0 ? (
                            <Alert variant="info">No packages available.</Alert>
                        ) : (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Select Package</Form.Label>
                                    <Form.Control as="select" onChange={handlePackageSelect}>
                                        <option value="">Choose a package</option>
                                        {packages.map(pkg => (
                                            <option key={pkg.id} value={pkg.id}>
                                                {pkg.bundle.summary} - Current Price: {pkg.cost} {pkg.currency}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label> {selectedPackage ? `New Price in ${selectedPackage.currency}` : 'New Price'}</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={newPrice}
                                        onChange={handlePriceChange}
                                        placeholder="Enter new price"
                                    />
                                </Form.Group>

                                <Button type="submit" disabled={!selectedPackage}>
                                    Update Price
                                </Button>
                            </Form>
                        )}
                    </Toast>
                </Container>
            </div>
        </div>
    );
};

export default Packages;
