// @ts-nocheck

/**
 * Project: spaces
 * File: SignUp
 * Created by Pennycodes on 2/4/2022.
 * Copyright spaces
 */

import React, {FormEvent, useEffect, useState} from "react";
import { CloseCircle, EyeOutline } from "react-ionicons";
// import { toAbsoluteUrl } from "../layout/helpers";
import {Link, useHistory} from "react-router-dom";
import {PageTitle, RightComponent, LeftComponent} from "../../layout/PageData";
import {toAbsoluteUrl} from "../../layout/helpers";
import SERVICES from "../../services";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { getApp } from "firebase/app";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {Constants} from "../../utils/constants";

const SignUp = () => {
    const history = useHistory();

    const [errorModal, setErrorModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [phoneValue, setPhoneValue] = useState("");
    // form
    const [firstName, setFirstName] = useState("");

    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [confirmPasswordValidationError, setConfirmPasswordValidationError] = useState('');
    const [isEmptyFirstname, setIsEmptyFirstname] = useState(false);
    const [isEmptyLastname, setIsEmptyLastname] = useState(false);
    const [isEmptyEmail, setIsEmptyEmail] = useState(false);
    const [isEmptyPassword, setIsEmptyPassword] = useState(false);
    const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);
    const [isRoleNotSelected, setIsRoleNotSelected] = useState(false);
    const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);
    const [phoneNumberValidationError,setPhoneNumberValidationError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    function hasText(text: string) {
        return text != null && text !== '';
    }

    useEffect(() => {
        if (hasText(firstName)) {
            setIsEmptyFirstname(false);
        }
        if (hasText(lastName)) {
            setIsEmptyLastname(false);
        }
        if (hasText(email)) {
            setIsEmptyEmail(false);
        }
        if (hasText(password)) {
            setIsEmptyPassword(false);
        }
        if (hasText(confirmPassword)) {
            setIsInvalidConfirmPassword(false);
        }
        if (password && !confirmPassword) {
            setIsInvalidConfirmPassword(false);
        }
        if (role) {
            setIsRoleNotSelected(false);
        }
        if (phoneValue) {
            setIsInvalidPhoneNumber(false);
        }
    }, [firstName, lastName, email, password, confirmPassword, phoneValue, role]);

    const areFieldsOkay = () => {
        const requiredFields = [firstName, lastName, email, password, confirmPassword, role];
        // is any of the fields empty
        const anyEmpty = requiredFields.some(field => !field);
        if (anyEmpty) {
            if (!hasText(firstName)) {
                setIsEmptyFirstname(true);
            }
            if (!hasText(lastName)) {
                setIsEmptyLastname(true);
            }
            if (!hasText(email)) {
                setIsEmptyEmail(true);
            }
            if (!hasText(password)) {
                setIsEmptyPassword(true);
            }
            if (!hasText(confirmPassword)) {
                setIsInvalidConfirmPassword(true);
                setConfirmPasswordValidationError('Confirm password is required');
            } else if (password && confirmPassword && confirmPassword !== password) {
                setIsInvalidConfirmPassword(true);
                setConfirmPasswordValidationError('Passwords do not match');
            }
            if (!role) {
                setIsRoleNotSelected(true);
            }
            if (!phoneValue) {
                setIsInvalidPhoneNumber(true);
                setPhoneNumberValidationError('Please enter mobile number');
            }
            // else if (phoneValue && phoneValue.length < 10) {
            // 	setIsInvalidPhoneNumber(true);
            // 	setPhoneNumberValidationError('This field must have 10 characters');
            // }

            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        if (password && confirmPassword && confirmPassword !== password) {
            setIsInvalidConfirmPassword(true);
            setConfirmPasswordValidationError('Passwords do not match');
        }
    }, [confirmPassword])

    let userData = {
        avatar: "https://storage.googleapis.com/maestro-8beff.appspot.com/user-543-_1626834155761_69ec79f5_1b40_4716_9958_1a6f557f6581.jpeg",
        companyId: 1,
        confirmPassword: confirmPassword,
        contacts: [
            {
                isContactable: true,
                isPrimary: true,
                type: "mobile",
                value: phoneValue,
            },
        ],
        firstName: firstName,
        lastName: lastName,
        password: password,
        username: email,
        role: 'ROLE_HOST'
    };

    async function onLoginSuccessful (res: AxiosResponse<any>) {
		const response = res.data;
		const responseData = response?.data;
		const currentUser = (responseData && responseData.length > 0) ? responseData[0] : null;

		SERVICES.saveUser(currentUser);

		const role = currentUser?.role;
		console.log('current user role is:', role);

		if (role != null) {
			switch (role) {
				case 'ROLE_ADMINISTRATOR':
				case 'ROLE_HOST':
				case 'ROLE_SUBSCRIBER_INDIVIDUAL':
				case 'ROLE_SUBSCRIBER_APPROVER':
				case 'ROLE_SUBSCRIBER_OWNER':
				case 'ROLE_HOST_OPERATIONS':
					history.push('/searchPage');
					break;

				default:
					history.push('/home');
			}
		}
    };

    const onLoginError = (res: any) => {
        console.log(res)
        setErrorModal(false);
    }

    const getFCMToken = async () => {
        try {
            const app = getApp();
            const messaging = getMessaging(app);
            const token = await getToken(messaging);
            console.log("FCM Token:", token);
            return token;
        } catch (error) {
            console.error("Error getting FCM token:", error);
            return null;
        }
    };

    const updateUserProfile = async (user: any, fcmToken: string) => {
        try {
            const payload = {
                firstName: user.firstName,
                lastName: user.lastName,
                contacts: [
                    {
                        deviceTokens: [],
                        isContactable: true,
                        isPrimary: true,
                        type: "mobile",
                        value: user.contacts.find((contact: any) => contact.type === "mobile")?.value,
                    },
                    {
                        deviceTokens: [],
                        isContactable: true,
                        isPrimary: false,
                        type: "deviceToken",
                        value: fcmToken,
                    },
                ],
                avatar: user.avatar,
            };

            const response = await axios.put(`${Constants.BASE_URL}/users/api/v1/users/${user.id}`, payload);
            console.log("User profile updated successfully:", response.data);
        } catch (error) {
            console.error("Error updating user profile:", error);
        }
    };

    const submitSignup = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!areFieldsOkay()) return;

        try {
            const res = await axios.post(`${Constants.BASE_URL}/users/api/v1/users`, userData);
            if (res.status === 201) {
                const userId = res.data.data[0].id;
                const fcmToken = await getFCMToken();
                if (fcmToken) {
                    await updateUserProfile(res.data.data[0], fcmToken);
                }
                history.push("/confirm");
                localStorage.setItem("currentUser", res.data.data[0].username);
            }
        } catch (err: any) {
            const { response: { data: { errors } } } = err;
            console.error(errors);
            setErrorModal(true);
            if (errors && Array.isArray(errors) && errors.length > 0) {
                const { message } = errors[0];
                setErrorMsg(message);
            }
        }
    };

    const googleSignUp = async () => {
        try {
            const auth = getAuth();
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: "select_account"
            });

            const result = await signInWithPopup(auth, provider);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const accessToken = credential?.accessToken;

            console.log(accessToken);

            const config: AxiosRequestConfig = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await axios.post(`${Constants.BASE_URL}/users/api/v1/utils/exchange-token`, {
                accessToken,
                companyId: 1,
                "provider": "GOOGLE"
            }, config);

            const fcmToken = await getFCMToken();
            if (fcmToken && response.data && response.data.data && response.data.data.length > 0) {
                await updateUserProfile(response.data.data[0], fcmToken);
            }

            onLoginSuccessful(response);
        } catch (error) {
            console.error("Google sign up error:", error);
            onLoginError(error);
        }
    };

    const togglePasswordVisibility = (e: any) => {
        e.preventDefault();

        setShowPassword(!showPassword);
    }

    const toggleConfirmPasswordVisibility = (e: any) => {
        e.preventDefault();

        setShowConfirmPassword(!showConfirmPassword);
    }
	// @ts-ignore
	const handleClick = (e: MouseEventHandler<HTMLSelectElement>) => {
		// @ts-ignore
		e.target.setAttribute("value", e.target.value);
	};

	// @ts-ignore
	const handleChange = (e: ChangeEventHandler<HTMLSelectElement>) => {
		// @ts-ignore
		e.target.setAttribute("value", e.target.value);
	};

	return (
		<div
			className="position-relative overflow-hidden"
			style={
				{
					// background: `url(${toAbsoluteUrl('/img/background.png')})`,
					// backgroundPosition: "center",
					// backgroundSize: "cover"
				}
			}
		>
			<PageTitle>{false}</PageTitle>
			<RightComponent>{false}</RightComponent>
			<LeftComponent>{false}</LeftComponent>

			<div className="section mt-2 position-relative p-0">
				<img
					className="w-100"
					decoding={"async"}
					src={toAbsoluteUrl("/img/Rectangle 18.png")}
					alt={"Logo"}
				/>

				<div
					className="section mt-2 d-flex flex-column position-absolute"
					style={{ bottom: "88px", left: "16px", width: "305px" }}
				>
					<h2 className={"text-capitalize text-white"}>Sign up</h2>
					<p
						className={"mt-2 text-white"}
						style={{
							width: "305px",
							fontStyle: " normal",
							fontWeight: 500,
							fontSize: "15px",
							lineHeight: "22px",
							color: "#FFFFFF",
						}}
					>
						Welcome to SPACIA, The Future Of Work, Stay & Play. Let’s create
						account now
					</p>
				</div>
			</div>
			<div
				className="section mb-5 p-2 position-relative"
				style={{
					backgroundColor: "white",
					borderTopRightRadius: "30px",
					borderTopLeftRadius: "30px",
					bottom: "21px",
					transform: "scaleX(1.02)",
					transformOrigin: "center",
				}}
			>
				<form onSubmit={submitSignup}>
					<div className="card-body pb-1">
						<div className="form-group basic">
							<div className="input-wrapper">
								<input
									type="text"
									className="form-control input-new-rounded floating-input"
									id="firstName"
									placeholder=""
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
								/>
								<label
									className=" floating-label-new-background"
									htmlFor="firstName"
									style={{
										fontStyle: "normal",
										fontWeight: 600,
										fontSize: "12px",
										lineHeight: "18px",
										letterSpacing: "0.02em",
										color: "#263238",
									}}
								>
									First Name
								</label>
								<i className="clear-input">
									<CloseCircle color={"#00000"} title={""} />
								</i>
							</div>
						</div>
						<div className="form-group basic">
							<div className="input-wrapper">
								<input
									type="text"
									className="form-control input-new-rounded floating-input"
									id="lastName"
									placeholder=""
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
								/>
								<label
									className=" floating-label-new-background"
									htmlFor="lastName"
									style={{
										fontStyle: "normal",
										fontWeight: 600,
										fontSize: "12px",
										lineHeight: "18px",
										letterSpacing: "0.02em",
										color: "#263238",
									}}
								>
									Last Name
								</label>
								<i className="clear-input">
									<CloseCircle color={"#00000"} title={""} />
								</i>
							</div>
						</div>
						<div className="form-group basic">
							<div className="input-wrapper">
								<input
									type="email"
									className="form-control input-new-rounded floating-input"
									id="email1"
									placeholder=""
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
								/>
								<label
									className=" floating-label-new-background"
									htmlFor="email1"
									style={{
										fontStyle: "normal",
										fontWeight: 600,
										fontSize: "12px",
										lineHeight: "18px",
										letterSpacing: "0.02em",
										color: "#263238",
									}}
								>
									Email Address
								</label>
								<i className="clear-input">
									<CloseCircle color={"#00000"} title={""} />
								</i>
							</div>
						</div>
						<div className="form-group basic ">
							<div className="input-wrapper">
								{/* <span className="did-input-group-prepend">
                                  <div className="did-input-group-text"><img src={toAbsoluteUrl('/img/ghana.svg')} alt={'gh'} width={15} /> &nbsp; +233</div>
                                 </span> */}
								<input
									type="number"
									min={0}
									minLength={9}
									maxLength={10}
									className="form-control input-new-rounded floating-input"
									id="phone"
									placeholder=" "
								/>
								<label
									className="floating-label-new-background"
									htmlFor="phone"
									style={{
										fontStyle: "normal",
										fontWeight: 600,
										fontSize: "12px",
										lineHeight: "18px",
										letterSpacing: "0.02em",
										color: "#263238",
									}}
								>
									Phone Number
								</label>
								<i className="clear-input">
									<CloseCircle color={"#00000"} title={""} />
								</i>
							</div>
						</div>
						<div className="form-group basic">
							<div className="input-wrapper">
								<input
									type="password"
									className="form-control floating-input input-new-rounded"
									id="password"
									autoComplete="off"
									placeholder=" "
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
								/>
								<label
									className="floating-label-new-background"
									htmlFor="password"
									style={{
										fontStyle: "normal",
										fontWeight: 600,
										fontSize: "12px",
										lineHeight: "18px",
										letterSpacing: "0.02em",
										color: "#263238",
									}}
								>
									Password
								</label>
								<i className="clear-input">
									<EyeOutline color={"#00000"} title={""} />
								</i>
							</div>
						</div>
						<div className="form-group basic">
							<div className="input-wrapper">
								<input
									type="password"
									className="form-control floating-input input-new-rounded"
									id="confirmPassword"
									autoComplete="off"
									placeholder=" "
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)}
								/>
								<label
									className="floating-label-new-background"
									htmlFor="confirmPassword"
									style={{
										fontStyle: "normal",
										fontWeight: 600,
										fontSize: "12px",
										lineHeight: "18px",
										letterSpacing: "0.02em",
										color: "#263238",
									}}
								>
									Confirm Password
								</label>
								<i className="clear-input">
									<EyeOutline color={"#00000"} title={""} />
								</i>
							</div>
						</div>


						<div className="mt-4">
							<Link
								to={"/home"}
								className={"btn btn-primary btn-block btn-lg"}
								style={{ borderRadius: "40rem" }}
							>
								Sign up
							</Link>
						</div>
						<div className="mt-2 text-center">
							<p className={"text-dark"}>
								Don't have an account? <Link to={"/signin"}>Sign In</Link>{" "}
							</p>
						</div>
						<div className="text-center mt-2">
							<p className="text-muted text-lowercase">or continue with</p>
						</div>
						<div className="d-flex flex-row justify-content-center align-items-center">
							{/*<button type="submit" className="btn">*/}
							{/*	<img*/}
							{/*		src={toAbsoluteUrl("/img/Facebook.png")}*/}
							{/*		alt={"Facebook Sign In"}*/}
							{/*	/>*/}
							{/*</button>*/}
							{/*<button type="button" className="btn">*/}
							{/*	<img*/}
							{/*		src={toAbsoluteUrl("/img/apple.png")}*/}
							{/*		alt={"Apple Sign In"}*/}
							{/*	/>*/}
							{/*</button>*/}
							<button type="submit" onClick={googleSignUp} className="btn">
								<img
									src={toAbsoluteUrl("/img/google.png")}
									alt={"Google Sign In"}
								/>
							</button>
						</div>

						<div className="mt-4 text-center">
							<p className={"text-muted text-capitalize text-center mb-auto"}>
								&copy;2022 SPACIA All rights reserved
							</p>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};
export default SignUp;

