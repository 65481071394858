import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { Constants } from '../../../utils/constants';

const userDetailsSubject = new BehaviorSubject({});
const updateUserDetailsSubject = new BehaviorSubject({});

export const userServiceRxJS = {
    userDetailsSubject: userDetailsSubject.asObservable(),
    getUserDetails,
    updateUserDetailsSubject: updateUserDetailsSubject.asObservable(),
    updateUserDetails,
    resetUpdateUserDetailsSubject,
    resetUserDetailsSubject
}

function getUserDetails(id) {
    axios.get(`${Constants.BASE_URL}/users/api/v1/users/` + id).then(e => {
        userDetailsSubject.next(e.data);
    }).catch(err => {
        console.log("error");
    });
}

function updateUserDetails(id, payload, token) {
    const headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
    };

    return axios.put(`${Constants.BASE_URL}/users/api/v1/users/` + id, payload, { headers }).then(e => {
        updateUserDetailsSubject.next(e.data);
        return e.data;  // Return the response data
    }).catch(err => {
        console.log("error.response");
        const error = { status: err.response.status, message: err.response.data?.message };
        updateUserDetailsSubject.next(error);
        return Promise.reject(error);  // Return the error
    });
}

function resetUpdateUserDetailsSubject() {
    updateUserDetailsSubject.next({});
}

function resetUserDetailsSubject() {
    userDetailsSubject.next({});
}
